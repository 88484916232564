import type {
  LocationPagedListResponse,
  LocationPagedList,
  LocationResponse,
  Location,
} from '@apiContract';

import { receivedDigitalApi } from './index';

const locationsApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocationsApiList: builder.query<LocationPagedList, void>({
      query: () => 'Location',
      providesTags: ['Location'],
      transformResponse: (response: LocationPagedListResponse) => response.objectData,
    }),
    getLocationById: builder.query<Location, number>({
      query: (id) => `Location/${id}`,
      transformResponse: (response: LocationResponse) => response.objectData,
    }),
  }),
});

export const { useGetLocationsApiListQuery, useGetLocationByIdQuery } = locationsApi;
