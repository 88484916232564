import { DeliveryRoute, SettingResponse } from '@apiContract';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const getRoutesList = (routes: DeliveryRoute[] | null | undefined) => {
  if (!routes || routes.length === 0) {
    return '';
  }
  return routes.map((route) => route.name).join(', ') + ' / ';
};

export const BEErrorsToRHF = (errors?: FetchBaseQueryError) => {
  const data = errors?.data as SettingResponse;
  const init: { [key: string]: string | undefined } = {};
  return Object.keys(data?.validationResult?.errors || {}).reduce((acc, key) => {
    acc[key] = data?.validationResult?.errors?.[key][0];
    return acc;
  }, init);
};
