import {
  CameraAltRounded,
  EmailRounded,
  FolderSharedRounded,
  GridOnRounded,
  InboxRounded,
  LinkedCameraRounded,
  LocalShippingRounded,
  MarkunreadMailboxRounded,
  PinDropRounded,
  UploadRounded,
} from '@mui/icons-material';

import {
  CaptureMethod,
  ContainerType,
  Piece,
  PieceAction,
  PieceAnnotation,
  PieceType,
  RecipientAutoDetection,
  RecipientAutoDetectionStatus,
  TrackingNumberAnnouncementStatus,
  Workflow,
} from '@apiContract';
import { getContainerTypeIdByWorkflow } from 'src/common/utils/container';
import { getDateStringWithoutTime } from 'src/common/utils/time';
import { v4 } from 'uuid';

import { WorkflowNameByEnum } from '@common/types/contractHelpers';

import { PackageIcon } from '@src/assets/icons/PackageIcon';

import { GroupPiecesStatus } from './components/ReviewItems/types';
import {
  ButtonTypes,
  CapturePiece,
  ColorStatus,
  IconColors,
  OnFileAddParams,
  ReceiveSettings,
  ReceiveToggleButtonGroupConfig,
  RecipientStatus,
  Steps,
} from './types';

export const tooltips = {
  PersonPinRounded: 'Assign',
  PersonPinRoundedLoading: 'Identifying recipient',
  LocationOnRounded: 'Edit Location',
  AddCommentRounded: 'Add Comment',
  CropRotateRounded: 'Crop and Rotate',
  Barcode: 'Edit Tracking Numbers',
  DeleteOutline: 'Delete',
};

export const recipientAlerts = {
  [RecipientAutoDetectionStatus.Identified]: {
    text: 'Recipient identified successfully',
    type: 'success',
  },
  [RecipientAutoDetectionStatus.IdentifiedBasedOnPreviousAssignment]: {
    text: 'Recipient identified based on previous assignment',
    type: 'success',
  },
  [RecipientAutoDetectionStatus.IdentifiedVerificationRequired]: {
    text: 'Recipient identified but requires validation',
    type: 'warning',
  },
  [RecipientAutoDetectionStatus.IdentifiedMultipleMatches]: {
    text: 'Possible recipient matches identified',
    type: 'error',
  },
  [RecipientAutoDetectionStatus.IdentifiedAndNotExist]: {
    text: 'Recipient identified but does not exist',
    type: 'error',
  },
  [RecipientAutoDetectionStatus.Failed]: {
    text: 'Unable to identify recipient',
    type: 'error',
  },
  ['notAssign']: {
    text: 'Recipient identified but is not assigned',
    type: 'error',
  },
  ['SetUp']: {
    text: 'Recipient identified automatically',
    type: 'info'
  }
};

type OnClickType = (prop?: any) => void;

export const getButtonConfig = (
  label: string,
  type: ButtonTypes,
  onClick: OnClickType = () => {},
  disabled = false,
  options: { captureDropdown?: boolean } = {},
) => ({
  label,
  type,
  onClick,
  disabled,
  ...options,
});

export const getIconConfig = (
  icon: string,
  isLoading: boolean,
  onClick: () => void,
  options: {
    color?: IconColors;
    disabled?: boolean;
    tooltip?: string;
  } = {},
) => ({
  icon,
  isLoading,
  onClick,
  color: options.color,
  tooltip: options.tooltip || tooltips[icon as keyof typeof tooltips],
  disabled: options.disabled,
});

export const getUserIconTooltip = (isLoading: boolean) =>
  tooltips[isLoading ? 'PersonPinRoundedLoading' : 'PersonPinRounded'];

export const getPieceDetection = (capturePiece?: CapturePiece | null) => {
  if (!capturePiece) {
    return null;
  }
  return capturePiece.selectedPieceDetection || capturePiece.pieceDetection;
};

export const getCapturePieceStatus = (capturePiece: CapturePiece): RecipientStatus | GroupPiecesStatus.failed => {
  const { piece } = capturePiece;

  if (capturePiece.status === 'failed') return GroupPiecesStatus.failed;

  // locker not send or no piece
  if (
    !piece ||
    (piece.workflow === Workflow.Lockers &&
      (!capturePiece.pieceAnnotations?.length ||
        !capturePiece.pieceAnnotations?.some(
          ({ pieceAnnotationsTrackingNumber }) =>
            pieceAnnotationsTrackingNumber?.announcementStatus === TrackingNumberAnnouncementStatus.Confirmed,
        )))
  ) {
    return RecipientStatus.invalid;
  }

  // recipient assigned
  if (piece.recipient) {
    return RecipientStatus.ready;
  }

  const pieceDetection = getPieceDetection(capturePiece);
  // recipient need verification
  if (pieceDetection?.status === RecipientAutoDetectionStatus.IdentifiedVerificationRequired) {
    return RecipientStatus.review;
  }
  // all else invalid
  return RecipientStatus.invalid;
};

export const getColorPieceStatus = (capturePiece?: CapturePiece | null) => {
  if (!capturePiece) return;
  const pieceStatus = getCapturePieceStatus(capturePiece);
  switch (pieceStatus) {
    case RecipientStatus.ready:
      return 'success';

    case RecipientStatus.review:
      return 'warning';

    case RecipientStatus.invalid:
      return 'error';

    default:
      return;
  }
};

export const getRecipientStatus = (piece?: Piece | null, status?: RecipientAutoDetectionStatus) => {
  if (piece?.recipient) {
    return RecipientStatus.ready;
  }
  switch (status) {
    case RecipientAutoDetectionStatus.IdentifiedVerificationRequired: {
      return RecipientStatus.review;
    }
    default:
      return RecipientStatus.invalid;
  }
};

export const getUserColorPieceStatus = (piece?: Piece | null, status?: RecipientAutoDetectionStatus) => {
  const recipientStatus = getRecipientStatus(piece, status);
  if (recipientStatus) {
    return ColorStatus[recipientStatus];
  }

  return;
};

export const parseUploadFiles = (uploadedFiles: Array<File | string>, sessionId: string) => {
  return uploadedFiles.reduce((acc: Array<OnFileAddParams>, file) => {
    const id = v4();
    acc.push({ file, fileId: id, sessionId });
    return acc;
  }, []);
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const getPieceRecipientParam = (
  piece: Piece | null,
  detection: RecipientAutoDetection | null,
  param: 'displayName' | 'userName',
) =>
  piece?.recipient?.[param] ||
  (detection?.status === RecipientAutoDetectionStatus.IdentifiedVerificationRequired && detection?.recipient?.[param]);

export const getDefaultValues = (containerTypes: ContainerType[], receiveSettings: ReceiveSettings) => ({
  [Workflow.DateReceived]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.DateReceived]),
    createdDate: getDateStringWithoutTime(new Date()),
    containerNumber: null,
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
  [Workflow.Carrier]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.Carrier]),
    createdDate: null,
    containerNumber: 'USPS',
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
  [Workflow.MailBox]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.MailBox]),
    createdDate: null,
    containerNumber: null,
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
  [Workflow.Location]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.Location]),
    createdDate: null,
    containerNumber: null,
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
  [Workflow.HighDensityMail]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.HighDensityMail]),
    createdDate: null,
    containerNumber: null,
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
  [Workflow.Lockers]: {
    containerTypeId: getContainerTypeIdByWorkflow(containerTypes, WorkflowNameByEnum[Workflow.Lockers]),
    createdDate: null,
    containerNumber: null,
    sortOrder: receiveSettings.ReceiveSortOrder,
  },
});

export const captureMethodOptions: ReceiveToggleButtonGroupConfig<CaptureMethod>[] = [
  {
    value: CaptureMethod.Single,
    label: 'Single',
    Icon: CameraAltRounded,
  },
  {
    value: CaptureMethod.Automated,
    label: 'Automated',
    Icon: LinkedCameraRounded,
    disabled: true,
  },
  {
    value: CaptureMethod.Images,
    label: 'Upload images',
    Icon: UploadRounded,
  },
];

export const itemTypeOptions: ReceiveToggleButtonGroupConfig<PieceType>[] = [
  {
    value: PieceType.Mail,
    label: 'Mail',
    Icon: EmailRounded,
  },
  {
    value: PieceType.Package,
    label: 'Packages',
    Icon: PackageIcon,
  },
];

export const workflowMailOptions: ReceiveToggleButtonGroupConfig<Workflow>[] = [
  {
    value: Workflow.DateReceived,
    label: 'Date received',
    Icon: InboxRounded,
  },
  {
    value: Workflow.HighDensityMail,
    label: 'High density mail',
    Icon: FolderSharedRounded,
  },
  {
    value: Workflow.MailBox,
    label: 'Mailbox',
    Icon: MarkunreadMailboxRounded,
  },
];

export const workflowPackagesOptions = (
  lockersAvailable: boolean = false,
): ReceiveToggleButtonGroupConfig<Workflow>[] => {
  const config = [
    {
      value: Workflow.Location,
      label: 'Location',
      Icon: PinDropRounded,
    },
    {
      value: Workflow.Carrier,
      label: 'Carrier',
      Icon: LocalShippingRounded,
    },
  ];
  if (lockersAvailable) {
    config.push({
      value: Workflow.Lockers,
      label: 'Lockers',
      Icon: GridOnRounded,
    });
  }
  return config;
};

export const nextStepReceiveConfig = {
  [CaptureMethod.Single]: Steps.Photo,
  [CaptureMethod.Automated]: Steps.Photo,
  [CaptureMethod.Images]: Steps.Images,
};

export const settingsNames = [
  'ReceiveMailWorkflow',
  'ReceivePackagesWorkflow',
  'ReceiveSortOrder',
  'LockersWorkflowEnable',
  'DefaultItemType',
];

export const isBarcodeLoading = (piece?: Piece | null, annotations?: PieceAnnotation[] | null, socketDetected?: boolean) => {
  if (!piece) return true;
  if (piece.workflow === Workflow.Lockers) {
    return !!annotations?.some(
      ({ pieceAnnotationsTrackingNumber }) => pieceAnnotationsTrackingNumber?.announcementStatus === TrackingNumberAnnouncementStatus.Sent,
    );
  }
  return !(piece.events?.some((event) => event.action === PieceAction.BarcodeDetectionComplete) || socketDetected);
}
