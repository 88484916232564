import { customShadows } from 'src/theme/custom-shadows';

export const sxCircleProgress = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

export const sxImageEditor = (isActive: boolean) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: isActive ? '25px' : 0,
  position: 'relative',
});

export const sxNotification = {
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  right: 16,
  top: 16,
  maxWidth: 500,
  backgroundColor: 'grey.800',
  marginLeft: '16px',
  padding: 1,
  borderRadius: 1,
  boxShadow: customShadows('dark').z8,
};

export const mdNotification = {
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  right: 24,
  top: 24,
  maxWidth: 500,
  backgroundColor: 'grey.800',
  padding: 1,
  borderRadius: 1,
  boxShadow: customShadows('dark').z8,
};

export const sxNotificationText = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  minWidth: 200,
};

export const sxNotificationClose = {
  display: 'flex',
  alignItems: 'center',
};
