import { useContext, useMemo } from 'react';

import { Setting, SettingType } from '@apiContract';

import { SettingsContext } from '@common/contexts/SettingsContext';

// TODO: Consider ways of improving applications settings typing.
// type SettingName =
//   | 'ComputerVision'
//   | 'ContainerDateFormat'
//   | 'EnvelopeValidationOpenAndScan'
//   | 'EnvelopeValidationRecycle'
//   | 'EnvelopeValidationShred'
//   | 'EnvelopeValidationOpenAndScan'
//   | 'EnvelopeValidationPickup'
//   | 'EnvelopeValidationMail'
//   | 'ReceiveMailWorkflow'
//   | 'ReceivePackagesWorkflow'
//   | 'ReceiveSortOrder'
//   | 'MailRequestOpenAndScanEnable'
//   | 'MailRequestForwardEnable'
//   | 'MailRequestMailEnable'
//   | 'MailRequestPickUpEnable'
//   | 'MailRequestRecycleEnable'
//   | 'MailRequestShredEnable'
//   | 'MailRequestDeliveryEnable'
//   | 'PackageRequestOpenAndScanEnable'
//   | 'PackageRequestForwardEnable'
//   | 'PackageRequestMailEnable'
//   | 'PackageRequestPickUpEnable'
//   | 'PackageRequestDeliveryEnable'
//   | 'PieceNoActivityEnable'
//   | 'PieceNoActivityEscalationEnable'
//   | 'PieceNoActivityDuration'
//   | 'PieceNoActivityEscalationDuration'
//   | 'RequireProofOfPickup'
//   | 'RequireProofOfDelivery'
//   | 'SendCompletedActionEnable'
//   | 'SendGotMailEnable'
//   | 'SendItemsToReviewEnable'
//   | 'SendItemsToReviewEscalationEnable'
//   | 'SendItemsToReviewSupervisorEscalationEnable'
//   | 'SendPendingRequestsEnable'
//   | 'SendReceivedPackageEnable'
//   | 'SendNotificationCommentEnable'
//   | 'DocumentNotViewedEnable'
//   | 'DocumentNotViewedDuration'
//   | 'DocumentNotViewedEscalationEnable'
//   | 'DocumentNotViewedEscalationDuration'
//   | 'ContainerUserRefFormat';

const getSettingValue = (setting: Setting, exactLevel?: keyof Setting) => {
  let settingValueString;

  switch (exactLevel) {
    case 'userSettingValue':
      settingValueString = setting.userSettingValue ?? setting.value;
      break;
    case 'mailroomSettingValue':
      settingValueString = setting.mailroomSettingValue ?? setting.value;
      break;
    case 'companySettingValue':
      settingValueString = setting.companySettingValue ?? setting.value;
      break;
    default:
      settingValueString =
        setting.userSettingValue ?? setting.mailroomSettingValue ?? setting.companySettingValue ?? setting.value;
  }

  switch (setting.type) {
    case SettingType.Boolean:
      return settingValueString === 'false' ? false : true;
    case SettingType.Number:
      return Number(settingValueString);
    default:
      return settingValueString;
  }
};

export const useSettings = (settingNames?: string[], exactLevel?: keyof Setting) => {
  const settings = useContext(SettingsContext);

  return useMemo(
    () =>
      settings
        .filter((setting) => (settingNames ? settingNames.includes(setting.name) : true))
        .reduce(
          (settingValues, setting) => ({ ...settingValues, [setting.name]: getSettingValue(setting, exactLevel) }),
          {},
        ),
    [settings, settingNames, exactLevel],
  );
};
