import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';

import { OidcProvider, TokenRenewMode } from '@axa-fr/react-oidc';
import { MotionLazy } from '@minimalsComponents/animate/motion-lazy';
import { SnackbarProvider } from '@minimalsComponents/snackbar';
import 'simplebar-react/dist/simplebar.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

import { configureBaseURLAxios } from '@common/api/axios';
import { AppInitializer } from '@common/components/AppInitializer';
import { AuthenticationError } from '@common/components/AuthenticationError';
import LoadingScreen from '@common/components/LoadingScreen';
import { SessionLost } from '@common/components/SessionLost';
import { RdSnackbarStyles } from '@common/uiKit/RdSnackbar/styles';

import { useScrollToTop } from '@src/hooks/use-scroll-to-top';

import { SettingsProvider } from './components/settings';
import Router from './routes';
import ThemeProvider from './theme';
import { getHostWithPrefix, isHostValid } from './common/utils/url';

if (!isHostValid()) {
  window.location.href = getHostWithPrefix('app');
}

const oidcConfiguration = {
  client_id: 'react',

  // This route is particularly important for Identity Server only,
  // because <OidcProvider /> will handle the ewdirect after successful login
  // it's own way, by returning the user to the route from which the redirect to
  // Identity Server's login page happened, or to the route passed to the login(ROUTE) function.
  redirect_uri: window.location.origin + '/auth/signin-oidc',

  // The "offline_access" scope is required for the <OidcProvider />
  // to start automatically refresh and provide new access_token.
  scope: 'openid offline_access dm_api profile email roles phone',

  token_renew_mode: TokenRenewMode.access_token_invalid,
  refresh_time_before_tokens_expiration_in_second: 30,
  authority: import.meta.env.VITE_APP_IDENTITY as string,

  // Workaround to handle the 'nonce'-related issue.
  // "@axa-fr/react-oidc" generates nonce code every time application tab is opened or reloaded.
  // This causes issues with authentication when multiple tabs with the app are opened.
  // More info here: https://github.com/AxaFrance/oidc-client/issues/961
  extras: { nonce: 'nonce' },
};

export default function App() {
  useScrollToTop();

  useEffect(() => {
    configureBaseURLAxios();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light',
          themeDirection: 'ltr',
          themeContrast: 'default',
          themeLayout: 'vertical',
          themeColorPresets: 'default',
          themeStretch: false,
        }}
      >
        <MotionLazy>
          <ThemeProvider>
            <SnackbarProvider>
              <RdSnackbarStyles />
              <OidcProvider
                configuration={oidcConfiguration}
                callbackSuccessComponent={LoadingScreen}
                authenticatingComponent={LoadingScreen}
                loadingComponent={LoadingScreen}
                authenticatingErrorComponent={AuthenticationError}
                sessionLostComponent={SessionLost}
              >
                <AppInitializer>
                  <Router />
                </AppInitializer>
              </OidcProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </MotionLazy>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
