import EmailIcon from '@mui/icons-material/Email';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Avatar, Box, Button, Card, CardContent, Container, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useCreateMyProfileMutation, useLazyGetMyProfileQuery } from '@api/user';
import { useOidc, useOidcAccessToken, useOidcUser } from '@axa-fr/react-oidc';
import Header from '@minimalsLayouts/common/header-simple';
import * as Sentry from '@sentry/react';
import PageErrorMFA from 'src/pages/PageErrorMFA';

import { rdBackSignalr } from '@common/api/signalr';
import LoadingScreen from '@common/components/LoadingScreen';
import { enqueueRdSnackbar } from '@common/uiKit/RdSnackbar';

type OidcRdUser = {
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
  email_verified: boolean;
  phone_number: string;
  role?: string | string[];
};

interface UserProfileGuardProps {
  children: React.ReactElement;
}

export const UserProfileGuard = ({ children }: UserProfileGuardProps) => {
  const [triggerGetMyProfile, { data: currentUser, error: currentUserError }] = useLazyGetMyProfileQuery();
  const [createMyProfile, { isLoading: isCreatingProfile }] = useCreateMyProfileMutation();
  const { accessToken } = useOidcAccessToken();
  const navigate = useNavigate();

  const [userInactive, setUserInactive] = useState<boolean>(false);
  const [profileCreationConfirmation, setProfileCreationConfirmation] = useState<boolean>(false);

  const { login, logout } = useOidc();
  const { oidcUser } = useOidcUser();
  const oidcRdUser = oidcUser as OidcRdUser | null;
  const [mfaError, setMfaError] = useState(false);

  const createUserProfile = useCallback(async () => {
    if (!oidcRdUser) return;
    await createMyProfile({
      email: oidcRdUser.email,
      firstName: oidcRdUser.given_name,
      lastName: oidcRdUser.family_name,
    }).unwrap();
  }, [oidcRdUser, createMyProfile]);

  useEffect(() => {
    if (oidcRdUser) triggerGetMyProfile();
  }, [oidcRdUser, triggerGetMyProfile]);

  useEffect(() => {
    if (oidcRdUser && currentUserError && 'data' in currentUserError) {
      if ((currentUserError.data as string) === 'ProfileNotActive') {
        setUserInactive(true);
        return;
      }

      if (['ProfileNotCreated', 'UnacceptableRole'].includes(currentUserError.data as string)) {
        if (oidcRdUser.role?.includes('ReceivedDigitalUser')) {
          createUserProfile();
          return;
        }

        setProfileCreationConfirmation(true);
        return;
      }

      if ((currentUserError.data as string) === 'CompanyRequiresMfa' && !mfaError) {
        setMfaError(true);
        return;
      }
      if ((currentUserError.data as string) !== 'CompanyRequiresMfa') {
        enqueueRdSnackbar('serverError', { variant: 'error' });
      }
      
    }
  }, [oidcRdUser, currentUserError, createUserProfile, navigate, mfaError]);

  useEffect(() => {
    if (!currentUser) return;
    Sentry.setUser({
      id: currentUser.id,
      username: `${currentUser.firstName} ${currentUser.lastName} (${currentUser.company?.name || '-'}: ${
        currentUser.userName
      })`,
      email: currentUser.email,
    });
  }, [currentUser]);

  useEffect(() => {
    if (!accessToken || rdBackSignalr.connectionState !== 0 || !currentUser) return;
    rdBackSignalr.connect(accessToken);

    return () => {
      rdBackSignalr.disconnect();
    };
  }, [accessToken, currentUser]);

  const confirmProfileCreation = async () => {
    await createUserProfile();
    login();
  };

  if (currentUser) return children;

  if (mfaError) {
    return (
      <>
        <Header />

        <Container component="main">
          <Stack
            spacing={5}
            sx={{
              py: 12,
              m: 'auto',
              maxWidth: 400,
              minHeight: '100vh',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <PageErrorMFA />
          </Stack>
        </Container>
      </>
    );
  }

  if (userInactive)
    return (
      <>
        <Header />

        <Container component="main">
          <Stack
            spacing={5}
            sx={{
              py: 12,
              m: 'auto',
              maxWidth: 480,
              minHeight: '100vh',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Typography variant="h3" sx={{ mb: 2 }}>
                You’ve been inactivated
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Sorry, you no longer have access to Received Digital. Please contact an admin to be reactivated.
              </Typography>
            </Box>

            <Box>
              <img src="/images/illustration_inactivated.png" alt="" style={{ padding: '40px 67px 80px' }} />

              <Button
                variant="contained"
                size="large"
                onClick={() => logout(undefined, { post_logout_redirect_uri: window.location.origin })}
              >
                Log Out
              </Button>
            </Box>
          </Stack>
        </Container>
      </>
    );

  if (profileCreationConfirmation)
    return (
      <>
        <Header />

        <Container component="main">
          <Stack
            spacing={5}
            sx={{
              py: 12,
              m: 'auto',
              maxWidth: 400,
              minHeight: '100vh',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Typography variant="h3" sx={{ mb: 2 }}>
                Create a new company?
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Would you like to create a new company in Received Digital using the Digitalized Software account below?
              </Typography>
            </Box>

            <Card>
              <CardContent sx={{ padding: '40px 52px !important' }}>
                <Avatar sx={{ width: 128, height: 128, mx: 'auto', mb: 3 }} />
                <Stack spacing={1}>
                  <Typography variant="h6">{`${oidcRdUser?.given_name} ${oidcRdUser?.family_name}`}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    <EmailIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                    {oidcRdUser?.email}
                  </Typography>
                  {oidcRdUser?.phone_number && (
                    <Typography variant="body2" color="text.secondary">
                      <LocalPhoneIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                      {oidcRdUser?.phone_number}
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>

            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                startIcon={<KeyboardArrowLeftIcon />}
                sx={{ flex: '1 1' }}
                onClick={() => logout()}
              >
                Log Out
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={{ flex: '1 1' }}
                onClick={() => confirmProfileCreation()}
                disabled={isCreatingProfile}
              >
                Create Company
              </Button>
            </Stack>
          </Stack>
        </Container>
      </>
    );

  return <LoadingScreen />;
};
