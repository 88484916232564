// import { List,ListItem, ListItemAvatar, ListItemButton, Avatar, ListItemText, Typography } from "@mui/material";
import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { User, UserMin } from '@apiContract';

import { sxList, sxNoResult } from '../../styles';
import Item from './Item';

type ListProps = {
  loadNextPage: (startIndex: number, stopIndex: number) => void | Promise<void>;
  isNextPageLoading: boolean;
  items: Array<User>;
  setSelectedUser: (user: User) => void;
  selectedUser?: User | UserMin | null;
  hasNextPage: boolean;
  changeTab: () => void;
};

const UserItem = ({ style, index, data }: ListChildComponentProps) => (
  <>
    <Item
      changeTab={data.changeTab}
      style={style}
      selected={!!data.selectedUser && !!data.items[index] && data.items[index]?.id === data.selectedUser?.id}
      item={data.items[index]}
      handleClick={() => data.items[index] && data.setSelectedUser(data.items[index] as User)}
    />
    {data.items.length === 0 && !data.isNextPageLoading ? (
      <Typography sx={sxNoResult} variant="body2">
        No results found
      </Typography>
    ) : null}
  </>
);

const List: FC<ListProps> = ({
  loadNextPage,
  isNextPageLoading,
  items,
  setSelectedUser,
  selectedUser,
  hasNextPage,
  changeTab,
}) => {
  const theme = useTheme();

  const itemCount = hasNextPage ? items.length + 1 : items.length + 1;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  return (
    <Box sx={{ ...sxList(theme) }}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
              {({ onItemsRendered, ref }) => {
                return (
                  <FixedSizeList
                    height={height}
                    width={width}
                    onItemsRendered={onItemsRendered}
                    itemCount={itemCount}
                    itemSize={60}
                    ref={ref}
                    itemData={{
                      changeTab,
                      selectedUser,
                      items,
                      setSelectedUser,
                      isNextPageLoading,
                    }}
                    className="customScrollBar"
                  >
                    {UserItem}
                  </FixedSizeList>
                );
              }}
            </InfiniteLoader>
          );
        }}
      </AutoSizer>
    </Box>
  );
};

export default List;
