// @mui
import { Box, Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

import { m } from 'framer-motion';

// components
import { MotionContainer, varBounce } from '../components/animate';

// ----------------------------------------------------------------------

export default function PageErrorMFA() {
  return (
    <>
      <Helmet>
        <title>Set up 2FA | Received Digital</title>
      </Helmet>

      <MotionContainer sx={{ width: 480 }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Set up 2FA to continue
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Your organization requires you to have two-factor authentication (2FA) set up in order to access Received
            Digital.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Box sx={{ my: 10 }}>
            <img src="/assets/illustrations/mfa.png" />
          </Box>
        </m.div>

        <Button
          component={RouterLink}
          target="_blank"
          to={`${import.meta.env.VITE_APP_IDENTITY}/Manage/TwoFactorAuthentication`}
          size="large"
          variant="contained"
          color="primary"
        >
          Set Up 2FA
        </Button>
      </MotionContainer>
    </>
  );
}
