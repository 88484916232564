import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/named
import { isArray, isUndefined } from 'lodash/fp';

import { enqueueRdSnackbar } from '@common/uiKit/RdSnackbar';

import type { RootState } from '../store';
import { SerializedError } from '@reduxjs/toolkit';

class FetchQueryError extends Error {
  constructor(error: FetchBaseQueryError, status: string | number = '') {
    super(error.data as string);
    this.name = `FetchQueryError: ${status}`;
  }
}

export const getErrorMessage = (error?: FetchBaseQueryError | SerializedError) => {
  if (!error || 'message' in error) return null;
  if ('data' in error) {
    if ((error.data as any)?.userMessage) {
      return (error.data as any).userMessage as string;
    }
    if (error.status === 500) {
      return 'Server error';
    }
  }
  return null;
};

const generateQueryApi = (url: string) => fetchBaseQuery({
  baseUrl: url,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
  paramsSerializer: (params) => {
    const parsedPArams = Object.keys(params).reduce((acc: Array<any>, key) => {
      if (isUndefined(params[key])) return acc;
      if (isArray(params[key])) {
        params[key].forEach((value: any) => {
          if (!isUndefined(value)) acc.push([key, value]);
        });
      } else {
        acc.push([key, params[key]]);
      }

      return acc;
    }, []);
    return new URLSearchParams(parsedPArams).toString();
  },
});

const baseQuery = generateQueryApi(`${import.meta.env.VITE_APP_API}/api`);

const lockersQuery = generateQueryApi(`${import.meta.env.VITE_APP_API_LOCKERS}/api`);

const baseQueryWithNotifications: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.data && (result.data as any).userMessage) {
    enqueueRdSnackbar((result.data as any).userMessage);
  }

  if (result.error) {
    const message = getErrorMessage(result.error);

    if (message) {
      enqueueRdSnackbar(message, { variant: 'error' });
    }

    const status = result.meta?.response?.status || result.error.status;

    Sentry.captureException(new FetchQueryError(result.error, status), (scope) => {
      scope.setTransactionName(message || (result.error.data as any)?.userMessage);     
      scope.setExtras({ error: result.error, request: args });
      return scope;
    });
  }

  return result;
};

export const lockersApi = createApi({
  reducerPath: 'lockersApiData',
  baseQuery: lockersQuery,
  tagTypes: [],
  endpoints: () => ({}),
});

export const receivedDigitalApi = createApi({
  reducerPath: 'receivedDigitalApiData',
  baseQuery: baseQueryWithNotifications,
  tagTypes: [
    'Cards',
    'Company',
    'CompanyVariable',
    'Container',
    'Containers',
    'Mailroom',
    'Piece',
    'Pieces',
    'PiecesCounters',
    'User',
    'Users',
    'Settings',
    'Subscription',
    'PieceAutoAction',
    'RSPiece',
    'RS',
    'PieceAnnotations',
    'DeliveryRoutes',
    'Location',
  ],
  endpoints: () => ({}),
});
