import { Box, Button, Container, Stack, Typography } from '@mui/material';

import { useOidc } from '@axa-fr/react-oidc';
import Header from '@minimalsLayouts/common/header-simple';

type Props = {
  withLogout?: boolean;
};

export const SessionLost = ({ withLogout = false }: Props) => {
  const { login, logout } = useOidc();

  return (
    <>
      <Header />

      <Container component="main">
        <Stack
          spacing={5}
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: 480,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Session timed out
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Your session has expired. Please re-authenticate.
            </Typography>
          </Box>

          <Box>
            <img src="/images/illustration_session_timeout.png" alt="" style={{ padding: '40px 67px 80px' }} />

            <Button variant="contained" color="primary" size="large" onClick={() => withLogout ? logout(undefined, { post_logout_redirect_uri: window.location.origin }) : login()}>
              Re-authenticate
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  );
};
