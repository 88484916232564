import { User, UserMin } from '@apiContract';
import { useGetLocationByIdQuery } from 'src/redux/api/locations';

export const useUserLocation = (user?: User | UserMin | null) => {
  let locationId;
  let location;

  if (user && 'location' in user && user.location?.id) {
    locationId = user.location?.id;
    location = user.location;
  } else if (user && 'locationId' in user) {
    locationId = user.locationId;
  }

  const { data: locationResponse, isLoading } = useGetLocationByIdQuery(locationId || 0, {
    skip: locationId === undefined || locationId === null || !!location,
  });

  if (!location && locationResponse && locationId) {
    location = locationResponse;
  }

  return { location, isLoading };
}