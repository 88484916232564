import { useEffect } from 'react';

import { Piece, RecipientAutoDetection } from '@apiContract';
import isArray from 'lodash/fp/isArray';
import { Options } from 'src/common/types/capture';

import { rdBackSignalr } from '@common/api/signalr';

import { PieceId } from '../types';
import { ActionTypes, Actions, FileOptionPayload } from './reducer';
import { AddEvent } from './useUpdateQueue';

export const useCaptureWebSockets = (
  dispatch: React.Dispatch<Actions>,
  setFileOption: (param: FileOptionPayload) => void,
  options: Options,
  addEvent: (param: AddEvent) => void,
) => {
  useEffect(() => {
    rdBackSignalr.registerEvent('OnPieceRecipientDetected', 'pieceCapture', (data: RecipientAutoDetection) => {
      const { pieceId } = data;
      if (!pieceId) return;
      dispatch({ type: ActionTypes.SET_FILE_DETECTION, payload: { id: pieceId, detection: data, addEvent } });
    });

    rdBackSignalr.registerEvent('OnPieceCropped', 'pieceCapture', ({ isSuccess, pieceId, croppedAsset }) => {
      if (isSuccess) {
        dispatch({ type: ActionTypes.SET_PIECE_ASSETS, payload: { id: pieceId, value: [croppedAsset] } });
      }

      setFileOption({ id: pieceId, option: 'isAutoCroppedCompleted', value: true });
    });

    rdBackSignalr.registerEvent('OnPieceTrackingNumberDetected', 'pieceCapture', (annotations) => {
      dispatch({ type: ActionTypes.MERGE_PIECE_BARCODES, payload: annotations.annotations });
      setFileOption({ id: annotations.pieceId, option: 'barcodeDetected', value: true });
    });

    rdBackSignalr.registerEvent('OnTrackingNumberStatusChanged', 'pieceCapture', (annotations) => {
      dispatch({ type: ActionTypes.MERGE_PIECE_BARCODES, payload: isArray(annotations) ? annotations : [annotations] });
    });

    rdBackSignalr.registerEvent('OnPieceThumbnailsCreated', 'pieceCapture', ({ pieceId, pieceThumbnailAssets }) => {
      dispatch({ type: ActionTypes.SET_PIECE_ASSETS, payload: { id: pieceId, value: pieceThumbnailAssets } });
    });

    const updateFunc = (e: CustomEvent<{ pieces: PieceId[] }>) => {
      setFileOption({ id: e.detail.pieces, option: 'isUpdating', value: true });
    };

    const updatedFunc = (e: CustomEvent<{ nextPieces: Piece[] }>) => {
      e.detail.nextPieces.forEach((item) => {
        setFileOption({ id: item.id, option: 'piece', value: item });
        setFileOption({ id: item.id, option: 'isUpdating', value: false });
      });
    };

    const errorFunc = (e: CustomEvent<{ pieces: Piece[] }>) => {
      e.detail.pieces.forEach((item) => {
        setFileOption({ id: item.id, option: 'isUpdating', value: false });
        setFileOption({ id: item.id, option: 'assignUserError', value: true });
      });
    };

    window.addEventListener('PiecesUpdating', updateFunc as EventListener);
    window.addEventListener('PiecesUpdated', updatedFunc as EventListener);
    window.addEventListener('PiecesUpdatedError', errorFunc as EventListener);

    return () => {
      rdBackSignalr.unregisterEvent('OnPieceRecipientDetected', 'pieceCapture');
      rdBackSignalr.unregisterEvent('OnPieceCropped', 'pieceCapture');
      rdBackSignalr.unregisterEvent('OnPieceThumbnailsCreated', 'pieceCapture');
      rdBackSignalr.unregisterEvent('OnPieceTrackingNumberDetected', 'pieceCapture');
      rdBackSignalr.unregisterEvent('OnTrackingNumberStatusChanged', 'pieceCapture');
      window.removeEventListener('PiecesUpdating', updateFunc as EventListener);
      window.removeEventListener('PiecesUpdated', updatedFunc as EventListener);
      window.removeEventListener('PiecesUpdatedError', errorFunc as EventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEvent]);
};
