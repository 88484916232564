import {
  ArchiveRounded,
  BusinessRounded,
  CameraAltRounded,
  CheckCircleOutlineRounded,
  DashboardRounded,
  HourglassTopRounded,
  InboxRounded,
  MailRounded,
  ModeOfTravel,
  PendingActionsRounded,
  PeopleAltRounded,
  PublishedWithChanges,
  SearchRounded,
  WarehouseRounded,
} from '@mui/icons-material';
import { useMemo } from 'react';

import { useGetMailroomPiecesCountQuery, useGetPiecesCountersQuery } from '@api/piece';
import Label from '@minimalsComponents/label';
import { PATH_KT_ADMIN, administrationRoutes, inboxRoutes, mailroomRoutes } from '@routes';

import { useUserPermissions } from '@common/hooks/useUserPermissions';

export const useNavData = () => {
  const { isAllowed } = useUserPermissions();

  const { data: piecesCounters } = useGetPiecesCountersQuery();
  const mailroomPiecesCount = useGetMailroomPiecesCountQuery();

  const navConfig = useMemo(() => {
    const config = [];

    if (isAllowed(['KTAdmin'])) {
      config.push({
        subheader: 'kt admin',
        items: [
          {
            id: 'onboarding',
            title: 'Onboarding',
            path: PATH_KT_ADMIN.onboarding,
          },
        ],
      });
    }

    if (isAllowed(['Admin'])) {
      config.push({
        subheader: 'administration',
        items: [
          {
            id: 'company',
            title: 'company',
            path: administrationRoutes.company(),
            icon: <BusinessRounded />,
          },
          {
            id: 'mailroom',
            title: 'mailroom',
            path: administrationRoutes.mailroom,
            icon: <MailRounded />,
          },
          {
            id: 'users',
            title: 'users',
            path: administrationRoutes.users,
            icon: <PeopleAltRounded />,
          },
          {
            id: 'automations',
            title: 'Automations',
            path: administrationRoutes.automations,
            icon: <PublishedWithChanges />,
          },
          {
            id: 'routes',
            title: 'Routes',
            path: administrationRoutes.routes,
            icon: <ModeOfTravel />,
          },
        ],
      });
    }

    if (isAllowed(['Mailroom'])) {
      config.push({
        subheader: 'mailroom',
        items: [
          {
            id: 'dashboard',
            title: 'dashboard',
            path: mailroomRoutes.dashboard,
            icon: <DashboardRounded />,
          },
          {
            id: 'receive',
            title: 'receive',
            path: mailroomRoutes.receive,
            icon: <CameraAltRounded />,
          },
          {
            id: 'active',
            path: mailroomRoutes.root,
            title: 'Active',
            icon: <WarehouseRounded />,
            children: [
              { title: 'Items', path: mailroomRoutes.items },
              { title: 'Storage Groups', path: mailroomRoutes.storageGroups },
            ],
          },
          {
            id: 'pending-requests',
            title: 'pending requests',
            path: mailroomRoutes.pendingRequest,
            icon: <PendingActionsRounded />,
            info: (
              <>
                {mailroomPiecesCount ? (
                  <Label variant="outlined" color="info">
                    {mailroomPiecesCount}
                  </Label>
                ) : null}
              </>
            ),
          },
          {
            id: 'search',
            title: 'search',
            path: mailroomRoutes.search,
            icon: <SearchRounded />,
          },
        ],
      });
    }

    if (isAllowed(['Recipient'])) {
      config.push({
        subheader: 'Inbox',
        items: [
          {
            id: 'new',
            title: 'New',
            path: inboxRoutes.inbox('new'),
            icon: <InboxRounded />,
            info: (
              <>
                {piecesCounters?.inboxCounters.inboxNewCount ? (
                  <Label variant="outlined" color="info">
                    {piecesCounters.inboxCounters.inboxNewCount}
                  </Label>
                ) : null}
              </>
            ),
          },
          {
            id: 'processing',
            title: 'In Process',
            path: inboxRoutes.inbox('processing'),
            icon: <HourglassTopRounded />,
            info: (
              <>
                {piecesCounters?.inboxCounters.inboxInProcessCount ? (
                  <Label variant="outlined" color="info">
                    {piecesCounters.inboxCounters.inboxInProcessCount}
                  </Label>
                ) : null}
              </>
            ),
          },
          {
            id: 'processed',
            title: 'Completed',
            path: inboxRoutes.inbox('processed'),
            icon: <CheckCircleOutlineRounded />,
            info: (
              <>
                {piecesCounters?.inboxCounters.inboxCompletedCount ? (
                  <Label variant="outlined" color="info">
                    {piecesCounters.inboxCounters.inboxCompletedCount}
                  </Label>
                ) : null}
              </>
            ),
          },
          {
            id: 'archive',
            title: 'Archive',
            path: inboxRoutes.inbox('archive'),
            icon: <ArchiveRounded />,
          },
        ],
      });
    }

    return config;
  }, [isAllowed, mailroomPiecesCount, piecesCounters]);

  return navConfig;
};
