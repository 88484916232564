import { Box, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { GetUsersFilteredRequest, useGetUsersFilteredQuery } from '@api/user';
import { Piece, RecipientAutoDetection, RecipientAutoDetectionStatus, User, UserMin } from '@apiContract';

import { useAssignmentRecipient } from '@common/components/Capture/hooks/useAssignmentRecipient';
import { AddEvent } from '@common/components/Capture/hooks/useUpdateQueue';

import Footer from '../Footer';
import Search from '../Search';
import Title from '../Title';
import AssignTO from './components/AssignTo';
import List from './components/List';
import UserForm from './components/UserForm';
import { sxP20, sxPaper, sxTab, sxTabsSection } from './styles';

const ITEMS_PER_PAGE = 20;

const initialFilters = {
  UserRole: ['Recipient'],
  ItemsPerPage: ITEMS_PER_PAGE,
  PageNumber: 0,
  SearchText: '',
  UserStatus: 1,
};

type UserListData = {
  piece?: Piece;
  pieceDetection?: RecipientAutoDetection;
  error: unknown;
  loading: boolean;
};

type UserListProps = {
  file: UserListData;
  onClose: () => void;
  addEvent: (param: AddEvent) => void;
};

const UserList = (props: UserListProps) => {
  const { file, onClose, addEvent } = props;
  const { pieceDetection, piece, error, loading } = file;
  const autoDetection = pieceDetection || piece?.recipientAutoDetection;
  const [isAutoDetectionApplied, setIsAutoDetectionApplied] = useState(!!autoDetection);
  const [tabIndex, setTabIndex] = useState(0);
  const [filters, setFilters] = useState<GetUsersFilteredRequest>({
    ...initialFilters,
    SearchText: (isAutoDetectionApplied && autoDetection?.recipientSearchString) || '',
  });

  const [items, setItems] = useState<Array<User>>([]);
  const [selectedUser, setSelectedUser] = useState<User | UserMin | undefined | null>(
    piece?.recipient || (isAutoDetectionApplied && autoDetection?.recipient) || null,
  );
  const { data, isFetching } = useGetUsersFilteredQuery(filters);
  const { assignRecipientToPiece } = useAssignmentRecipient(addEvent);

  const loadNextPage = useCallback((startIndex: number) => {
    setFilters((prev) => ({
      ...prev,
      PageNumber: Math.floor(startIndex / ITEMS_PER_PAGE),
      ItemsPerPage: ITEMS_PER_PAGE,
    }));
  }, []);

  const selectUser = (user: User) => {
    setSelectedUser(user);
  };

  useEffect(() => {
    if (data?.pageNumber === 0) {
      setItems(data.items || []);
    } else {
      setItems((prev) => [...prev, ...(data?.items || [])]);
    }
  }, [data]);

  if (
    !selectedUser &&
    autoDetection?.recipient &&
    autoDetection?.status === RecipientAutoDetectionStatus.IdentifiedVerificationRequired
  ) {
    setSelectedUser(autoDetection.recipient);
  }

  const onSearch = (value: string) => {
    setFilters({ ...initialFilters, SearchText: value, PageNumber: 0 });
  };

  const changeTab = () => setTabIndex((prev) => (!prev ? 1 : 0));

  const hasNextPage = (data?.totalCount || 0) > items.length;

  const addUserToPiece = (user?: User) => {
    const currentUser = user || selectedUser;

    if (piece && currentUser) {
      assignRecipientToPiece(currentUser, piece);
    }

    if (user) {
      setSelectedUser(user);
    }
  };

  const handleSubmit = () => {
    addUserToPiece();
    onClose();
  };

  const applyAutoDetection = () => {
    setIsAutoDetectionApplied(true);
    if (autoDetection?.recipient) {
      setSelectedUser(autoDetection?.recipient);
    }

    if (autoDetection?.recipientSearchString) {
      onSearch(autoDetection?.recipientSearchString);
    }
  };

  const isConfirm =
    !!selectedUser &&
    selectedUser.id === autoDetection?.recipient?.id &&
    !piece?.recipient &&
    [
      RecipientAutoDetectionStatus.Identified,
      RecipientAutoDetectionStatus.IdentifiedBasedOnPreviousAssignment,
      RecipientAutoDetectionStatus.IdentifiedVerificationRequired,
    ].includes(autoDetection?.status);

  return (
    <Paper elevation={3} sx={{ ...sxPaper }}>
      <Title title="Assign" sx={sxP20} onClose={onClose} />
      <Box component="section" sx={{ ...sxTabsSection(tabIndex) }}>
        <Box display="flex" sx={sxTab}>
          <AssignTO
            user={selectedUser}
            recipientDetection={autoDetection}
            isAutoDetectionApplied={isAutoDetectionApplied}
            applyAutoDetection={applyAutoDetection}
            piece={piece}
            showAlert={
              !error && !!autoDetection && (!piece?.recipient || autoDetection?.recipientId === piece?.recipient?.id)
            }
            loading={loading || !autoDetection}
          />
          <Search setSearchResult={onSearch} sx={sxP20} defaultSearch={filters.SearchText} isLoading={isFetching} />
          <List
            changeTab={changeTab}
            loadNextPage={loadNextPage}
            isNextPageLoading={isFetching}
            items={items}
            setSelectedUser={selectUser}
            selectedUser={selectedUser}
            hasNextPage={hasNextPage}
          />
          <Footer
            sx={sxP20}
            disabled={!selectedUser?.id || selectedUser?.id === piece?.recipient?.id}
            title={isConfirm ? 'Confirm Recipient' : 'Save Changes'}
            handleSubmit={handleSubmit}
          />
        </Box>
        <UserForm changeTab={changeTab} assignUser={addUserToPiece} />
      </Box>
    </Paper>
  );
};

export default UserList;
