import { useRef, useState } from 'react';
import { PieceAnnotation, TrackingNumberAnnouncementStatus, TrackingNumberValidationStatus, Workflow } from '@apiContract';
import { PieceAnnotations } from '../components/Capture/types';
import { isTrackingNumberValid } from '../utils/annotations';

interface ImagePreviewProps {
  imageDataUrl?: string;
  annotations?: PieceAnnotations;
  width?: number;
  height?: number;
  onAnnotationClick?: () => void;
}

enum AnnotationColor {
  Red = 0,
  Green = 1,
  Gray = 2,
}

type ImgRef = {
  imageDataUrl?: string;
  imgObject?: HTMLImageElement;
};

const colorByAnnotation = {
  [AnnotationColor.Green]: '104, 186, 63',
  [AnnotationColor.Red]: '255, 78, 43',
  [AnnotationColor.Gray]: '0, 0, 0',
};

export type SetPreviewPaneDimensions = (width: number, height: number) => void;

const getAnnotationColor = (annotation: PieceAnnotation, workflow: Workflow, isAnyValid?: boolean) => {
  const { announcementStatus, validationStatus } = annotation.pieceAnnotationsTrackingNumber || {};
  const isValid = isTrackingNumberValid(annotation, workflow);
  switch (true) {
    case (isValid):
      return AnnotationColor.Green;
    case (isAnyValid || workflow !== Workflow.Lockers || (announcementStatus === TrackingNumberAnnouncementStatus.Sent && validationStatus === TrackingNumberValidationStatus.Valid)):
      return AnnotationColor.Gray;
    default:
      return AnnotationColor.Red;
  }
};

const drawAnnotations = (ctx?: CanvasRenderingContext2D | null, annotations?: PieceAnnotations, isAnyValid?: boolean) => {
  if (!ctx) return [];
  const paths: Path2D[] = [];
  annotations?.items?.forEach((annotation) => {
    if (annotation.boundingBox && annotations.workflow) {
      const rgb = colorByAnnotation[getAnnotationColor(annotation, annotations.workflow, isAnyValid)];
      const coords = annotation.boundingBox;
      if (!coords) return;
      ctx.strokeStyle = `rgba(${rgb})`;
      ctx.fillStyle = `rgba(${rgb})`;
      ctx.setLineDash([10, 7]);
      ctx.lineWidth = 5;

      ctx.beginPath();
      ctx.moveTo(coords.topLeftX, coords.topLeftY);
      ctx.lineTo(coords.topRightX, coords.topRightY);
      ctx.lineTo(coords.bottomRightX, coords.bottomRightY);
      ctx.lineTo(coords.bottomLeftX, coords.bottomLeftY);
      ctx.closePath();
      ctx.stroke();

      ctx.fillStyle = `rgba(${rgb}, 0.5)`;
      const padding = 2;
      const fillCoords = {
        topLeftX: coords.topLeftX + padding,
        topLeftY: coords.topLeftY + padding,
        topRightX: coords.topRightX - padding,
        topRightY: coords.topRightY + padding,
        bottomRightX: coords.bottomRightX - padding,
        bottomRightY: coords.bottomRightY - padding,
        bottomLeftX: coords.bottomLeftX + padding,
        bottomLeftY: coords.bottomLeftY - padding,
      };

      const path = new Path2D();
      path.moveTo(fillCoords.topLeftX, fillCoords.topLeftY);
      path.lineTo(fillCoords.topRightX, fillCoords.topRightY);
      path.lineTo(fillCoords.bottomRightX, fillCoords.bottomRightY);
      path.lineTo(fillCoords.bottomLeftX, fillCoords.bottomLeftY);
      ctx.fill(path);

      paths.push(path);
        
      const centerX = (coords.topLeftX + coords.topRightX) / 2;
      const centerY = (coords.topLeftY + coords.bottomLeftY) / 2;

      const text = annotation.textValue;

      if (text) {
        ctx.font = '26px Public Sans';
        const textMetrics = ctx.measureText(text || '');
        const textWidth = textMetrics.width;
        const textHeight = 30;

        const bgX = centerX - textWidth / 2 - 5;
        const bgY = centerY - textHeight / 2 - 5;
        const bgWidth = textWidth + 20;
        const bgHeight = textHeight + 10;
        const radius = bgHeight / 2;

        ctx.fillStyle = `rgba(${rgb})`;
        ctx.beginPath();
        ctx.moveTo(bgX + radius, bgY);
        ctx.lineTo(bgX + bgWidth - radius, bgY);
        ctx.arc(bgX + bgWidth - radius, bgY + radius, radius, -0.5 * Math.PI, 0.5 * Math.PI, false);
        ctx.lineTo(bgX + radius, bgY + bgHeight);
        ctx.arc(bgX + radius, bgY + radius, radius, 0.5 * Math.PI, 1.5 * Math.PI, false);
        ctx.closePath();
        ctx.fill();
          
        ctx.fillStyle = 'white';
        ctx.fillText(text || '', centerX + 3 - textWidth / 2, centerY + textHeight / 4);
      }
    }    
  });
  return paths;
};

const clear = (ctx?: CanvasRenderingContext2D | null, canvasRef?: React.RefObject<HTMLCanvasElement>) => {
  if (!ctx || !canvasRef?.current) return;
  ctx.clearRect(0, 0, canvasRef.current.width || 0, canvasRef.current.height || 0);
};

export const ImagePreview = ({ imageDataUrl, width, height, annotations, onAnnotationClick }: ImagePreviewProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<ImgRef | null>(null);
  const annotationRef = useRef<PieceAnnotations | null>(null);
  const [paths, setPaths] = useState<Path2D[]>([]);

  const isAnyValid = annotations?.items?.some(
    (annotation) =>
      annotation.pieceAnnotationsTrackingNumber?.announcementStatus === TrackingNumberAnnouncementStatus.Confirmed,
  );

  if (annotations && (annotations !== annotationRef.current)) {
    annotationRef.current = annotations;
    if (imgRef.current?.imgObject) {
      const ctx = canvasRef.current?.getContext('2d');
      clear(ctx, canvasRef);
      ctx?.drawImage(imgRef.current.imgObject, 0, 0);
      setPaths(drawAnnotations(ctx, annotations, isAnyValid));
    }
  }

  if (imageDataUrl && imageDataUrl !== imgRef.current?.imageDataUrl) {
    const img = document.createElement('img');
    img.src = imageDataUrl;
    imgRef.current = { imageDataUrl };
    img.onload = () => {
      imgRef.current = imgRef.current ? { ...imgRef.current, imgObject: img } : { imgObject: img };
      if (!canvasRef.current) return;
      canvasRef.current.width = img.width;
      canvasRef.current.height = img.height;
      const ctx = canvasRef.current.getContext('2d');
      clear(ctx, canvasRef);
      ctx?.drawImage(img, 0, 0);
      if (annotationRef.current?.items) {
        setPaths(drawAnnotations(ctx, annotationRef.current));
      }
    };
  }

  const handleClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!onAnnotationClick) return;
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
  
    const rect = canvas?.getBoundingClientRect();
    if (!rect) return;
    const x = event?.clientX - rect.left;
    const y = event?.clientY - rect.top;
    
    paths.some((path) => {
      if (ctx?.isPointInPath(path, x, y)) {
        onAnnotationClick();
        return true;
      }
    });
  };

  return (
    <div
      className="rd-image-preview"
      style={{
        display: 'flex',
        width,
        height,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <canvas style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} ref={canvasRef} onClick={onAnnotationClick ? handleClick : undefined} />
    </div>
  );
};
