import { PieceAnnotation, TrackingNumberAnnouncementStatus, TrackingNumberValidationStatus, Workflow } from '@apiContract';

import { getValidationStatuses } from '@common/utils/annotations';

enum Alert {
  NoTracking = 'No tracking numbers were identified',
  Identified = 'Tracking number(s) identified successfully',
  LockersIdentified = 'Tracking number(s) identified and sent to lockers successfully',
  UnableTracking = 'Some tracking numbers were unable to be sent to lockers',
  NotUnique = 'This item does not have any unique tracking numbers',
  Duplicate = 'This item looks like it was already received. Please delete it or its duplicate',
}

enum Severity {
  Success = 'success',
  Error = 'error',
}

const tooltips = {
  barcode: 'Reading barcodes.',
  lockers: 'Sending to lockers.',
};

export const getAlert = (annotations: Array<PieceAnnotation>) => {
  const { validation } = getValidationStatuses(annotations);

  if (validation.get(TrackingNumberValidationStatus.Valid) === validation.size) {
    return { text: Alert.Identified, severity: Severity.Success };
  }

  return;
};

export const getLockersAlert = (annotations: Array<PieceAnnotation> | null, filtered: Array<PieceAnnotation>) => {
  if (!annotations?.length) {
    return;
  }

  if (!filtered.length) {
    return { text: Alert.NoTracking, severity: Severity.Error };
  }

  const { validation, announce } = getValidationStatuses(filtered);

  const sent = announce.get(TrackingNumberAnnouncementStatus.Sent) || 0;

  if (announce.get(TrackingNumberAnnouncementStatus.Confirmed)) {
    return { text: Alert.LockersIdentified, severity: Severity.Success };
  }

  const failed = announce.get(TrackingNumberAnnouncementStatus.Failed) || 0;
  const badFormat = validation.get(TrackingNumberValidationStatus.BadFormat) || 0;

  if (failed && !sent) {
    return { text: Alert.UnableTracking, severity: Severity.Error };
  }

  const conflict = validation.get(TrackingNumberValidationStatus.Conflict) || 0;

  if (conflict + badFormat === annotations.length) {
    return { text: Alert.NotUnique, severity: Severity.Error };
  }
};

export const getTooltip = (workflow: Workflow, annotation?: PieceAnnotation) => {
  if (!annotation) {
    return tooltips.barcode;
  }
  if (
    workflow === Workflow.Lockers &&
    annotation.pieceAnnotationsTrackingNumber?.announcementStatus === TrackingNumberAnnouncementStatus.Sent
  ) {
    return tooltips.lockers;
  }

};
