import { useEffect, useState } from 'react';

import { DeliveryRoute, DeliveryRoutePagedListResponse } from '@apiContract';
import axios from 'axios';

export const useRoutesByLocation = (locationId: number | null | undefined) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<DeliveryRoute[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (locationId === null || locationId === undefined) {
      setData([]);
      setIsLoading(false);
      setError(null);
      return;
    }

    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await axios.get<DeliveryRoutePagedListResponse>('DeliveryRoutes', {
          params: { locationId },
        });
        setData(response.data.objectData.items || []);
      } catch (err) {
        console.error('Error fetching routes:', err);
        setError('Failed to fetch routes');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [locationId]);

  return { isLoading, data, error };
};
