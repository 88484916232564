import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

const carrierOptions = [
  {
    label: 'USPS',
    value: 'USPS',
  },
  {
    label: 'UPS',
    value: 'UPS',
  },
  {
    label: 'FedEx',
    value: 'FedEx',
  },
  {
    label: 'Amazon',
    value: 'Amazon',
  },
  {
    label: 'DHL',
    value: 'DHL',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

type SelectMailCarrierProps = {
  carrier?: string | null;
  setCarrier: (value: string) => void;
};

export const SelectMailCarrier = (props: SelectMailCarrierProps) => {
  const { carrier, setCarrier } = props;

  return (
    <FormControl fullWidth>
      <InputLabel id="select-mail-carrier" sx={{ px: '2px' }}>
        Carrier
      </InputLabel>
      <Select
        labelId="select-mail-carrier"
        label="Carrier"
        value={carrier || undefined}
        onChange={(event: SelectChangeEvent) => {
          setCarrier(event.target.value);
        }}
      >
        {carrierOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
