import { useGetMyProfileQuery } from '@api/user';
import { Piece, PieceAction, User, UserMin } from '@apiContract';

import { AddEvent } from './useUpdateQueue';

export const useAssignmentRecipient = (addEvent: (param: AddEvent) => void) => {
  const { data: profile } = useGetMyProfileQuery();

  const assignRecipientToPiece = (user: User | UserMin, piece: Piece) => {
    if (profile && piece) {
      addEvent({
        event: { id: piece.id, recipientId: user.id },
        action: PieceAction.Assign,
        source: 'user',
      });
    }
  };
  return { assignRecipientToPiece };
};
