import { Error } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Alert, AlertColor, Box, Button, Paper, Stack } from '@mui/material';
import { useState } from 'react';

import { Piece, PieceAnnotation, TrackingNumberAnnouncementStatus, Workflow, PieceAction } from '@apiContract';
import { clearServiceAnnotations } from 'src/common/utils/annotations';

import Title from '@common/components/DrawerContent/Title';

import TrackForm from './components/TrackForm';
import TrackInput from './components/TrackInput';
import { sxP20, sxPaper } from './styles';
import { getAlert, getLockersAlert } from './utils';

type BarcodeProps = {
  onClose: () => void;
  piece?: Piece;
  pieceAnnotations: PieceAnnotation[] | null;
  workflow: Workflow;
  onCreate?: (piceAnnotation: PieceAnnotation) => void;
  noAddButton?: boolean;
  detected?: boolean;
};

const Barcode = ({ onClose, piece, pieceAnnotations, workflow, onCreate, noAddButton, detected }: BarcodeProps) => {
  const [open, setOpen] = useState(false);

  const isLoading = !detected && !piece?.events.some((event) => event.action === PieceAction.BarcodeDetectionComplete);

  const filteredAnnotations = clearServiceAnnotations(pieceAnnotations);

  const alert =
    workflow === Workflow.Lockers
      ? getLockersAlert(pieceAnnotations, filteredAnnotations)
      : getAlert(filteredAnnotations);

  const isAnyValid = pieceAnnotations?.some(
    (annotation) =>
      annotation.pieceAnnotationsTrackingNumber?.announcementStatus === TrackingNumberAnnouncementStatus.Confirmed,
  );

  return (
    <Paper sx={sxPaper}>
      <Title title="Tracking Numbers" onClose={onClose} sx={sxP20} />
      <Stack spacing={3} p={3}>
        {alert && (
          <Alert icon={alert.severity === 'error' ? <Error /> : undefined} severity={alert.severity as AlertColor}>
            {alert.text}
          </Alert>
        )}
        {isLoading ? (
          <TrackInput workflow={workflow} />
        ) : (
          filteredAnnotations.map((item) => (
            <TrackInput isAnyValid={isAnyValid} key={item.id} item={item} workflow={workflow} />
          ))
        )}

        {!noAddButton && (
          <Box display="flex" justifyContent="flex-start">
            <Button variant="text" startIcon={<AddRoundedIcon />} onClick={() => setOpen((prev) => !prev)}>
              Add Tracking Number
            </Button>
          </Box>
        )}
        {open && piece && <TrackForm pieceId={piece.id} onClose={() => setOpen(false)} onCreate={onCreate} />}
      </Stack>
    </Paper>
  );
};

export default Barcode;
