import { SvgIconProps } from '@mui/material';
import { SxProps } from '@mui/system';

import {
  Piece,
  PieceAnnotation,
  PieceType,
  ReceiveSession,
  ReceiveSessionContainer,
  RecipientAutoDetection,
  Workflow,
} from '@apiContract';

import { ItemValue, SortingOrder, WorkflowMail, WorkflowPackages } from '@common/types/contractHelpers';

export enum Steps {
  ReceiveConfig = 'config',
  Photo = 'photo',
  Review = 'review',
  Edit = 'edit',
  Crop = 'crop',
  ReviewItems = 'reviewitems',
  Images = 'images',
  Loading = 'loading',
  NotFound = 'notFound',
}

export enum DrawerTypes {
  User = 1,
  Location = 2,
  Comments = 3,
  Barcode = 4,
}

export type IconColors = 'success' | 'warning' | 'error' | 'default';
export type ButtonTypes = 'primary' | 'secondary' | 'inherit';

export type ButtonConfig = {
  label: string;
  type: ButtonTypes;
  onClick: () => void;
  disabled?: boolean;
  captureDropdown?: boolean;
};

export type IconConfig = {
  icon: string;// | React.ElementType;
  onClick: () => void;
  isLoading?: boolean;
  color?: IconColors;
  sx?: SxProps;
  retryContent?: number;
  tooltip?: string;
  disabled?: boolean;
};

export type PieceStatus =
  | 'created'
  | 'queued'
  | 'processing'
  | 'completed'
  | 'failed'
  | 'detected'
  | 'loadedFromServer';

export type IdV4 = string;
export type PieceId = number;

export type CaptureId = IdV4 | PieceId;

export type CapturePiece = {
  id: CaptureId;
  status: PieceStatus;
  photo?: File | string;
  piece: Piece | null;
  editImage?: string;
  prevImage?: string | null;
  pieceDetection: RecipientAutoDetection | null;
  selectedPieceDetection: RecipientAutoDetection | null;
  isUpdating: boolean;
  assignUserError: boolean;
  isAutoCroppedCompleted: boolean;
  pieceAnnotations: Array<PieceAnnotation> | null;
  barcodeDetected?: boolean;
};

export type MapCapturePiece = Record<CaptureId, CapturePiece>;
export type MapDetectedRecipient = Record<PieceId, RecipientAutoDetection>;
export type MapDetectedTrackingNumbers = Record<PieceId, PieceAnnotation>;

export type OnPieceCreateType = {
  fileId: string;
  piece?: Piece;
  status: PieceStatus;
};

export type OnFileAddParams = {
  fileId: string;
  sessionId: string;
  file?: File | string;
  resize?: boolean;
};

export type UploadRef = {
  upload: () => void;
  removeAll: () => void;
} | null;

export enum RecipientStatus {
  ready = 'ready',
  review = 'review',
  invalid = 'invalid',
}

export enum ColorStatus {
  ready = 'success',
  review = 'warning',
  invalid = 'error',
}

export enum DialogProcess {
  Uploading = 'Uploading',
  Unresolved = 'Unresolved',
  UnresolvedByClose = 'UnresolvedByClose',
}

export type ReceiveSettings = {
  ReceiveMailWorkflow: WorkflowMail;
  ReceivePackagesWorkflow: WorkflowPackages;
  ReceiveSortOrder: SortingOrder;
};

export interface ReceiveToggleButtonGroupConfig<T> {
  value: T;
  label: string;
  Icon: React.FC<SvgIconProps>;
  disabled?: boolean;
}

export type DefaultReceiveSettingsParams = {
  ReceiveMailWorkflow: WorkflowMail;
  ReceivePackagesWorkflow: WorkflowPackages;
  ReceiveSortOrder: SortingOrder;
  DefaultItemType: ItemValue;
};

export type CurrentSessionProps = {
  isNew: boolean;
  id?: string | null;
  params?: {
    pieceType: PieceType;
    workflow?: Workflow;
    storageGroupData?: ReceiveSessionContainer;
  };
  receiveSession?: ReceiveSession;
};

export type PieceAnnotations = {
  workflow?: Workflow,
  items?: PieceAnnotation[] | null,
};
