import { Error } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FC } from 'react';

import { Piece, RecipientAutoDetection, RecipientAutoDetectionStatus, User, UserMin } from '@apiContract';
import { m } from 'framer-motion';
import Iconify from 'src/components/iconify';

import { recipientAlerts } from '@common/components/Capture/helper';
import { sxSearchCircle } from '@common/components/Capture/styles';
import { useRoutesByLocation } from '@common/hooks/useRoutesByLocation';
import { getRoutesList } from '@common/utils/mappers';

import { sxAssignButton, sxAssignToBox, sxAvatarBigSize, sxIconGrey500 } from '../styles';
import { useUserLocation } from 'src/common/hooks/useUserLocation';

type AssignToProps = {
  recipientDetection?: RecipientAutoDetection;
  user?: User | UserMin | null;
  showAlert: boolean;
  loading: boolean;
  piece?: Piece | null;
  isAutoDetectionApplied: boolean;
  applyAutoDetection: () => void;
};

const getAlert = (
  isAutodetectionApplied: boolean,
  showAlert: boolean,
  recipientDetection?: RecipientAutoDetection,
  piece?: Piece | null,
) => {
  if (recipientDetection && showAlert && piece) {
    if (
      !isAutodetectionApplied &&
      recipientDetection.status !== RecipientAutoDetectionStatus.Failed &&
      !piece.recipient
    ) {
      return recipientAlerts.SetUp;
    }
    if (
      !piece.recipient &&
      recipientDetection.recipient &&
      [
        RecipientAutoDetectionStatus.Identified,
        RecipientAutoDetectionStatus.IdentifiedBasedOnPreviousAssignment,
      ].includes(recipientDetection.status)
    ) {
      return recipientAlerts.notAssign;
    }
    return recipientAlerts[recipientDetection.status];
  }
  return null;
};

const AssignTO: FC<AssignToProps> = ({
  applyAutoDetection,
  piece,
  user,
  showAlert,
  recipientDetection,
  isAutoDetectionApplied,
  loading = false,
}) => {
  const theme = useTheme();

  let locationId;

  if (user && 'location' in user && user.location?.id) {
    locationId = user.location?.id;
  } else if (user && 'locationId' in user) {
    locationId = user.locationId;
  }

  const routes = useRoutesByLocation(locationId);
  const alert = getAlert(isAutoDetectionApplied, showAlert, recipientDetection, piece);

  const { location, isLoading: isLocationResponseLoading } = useUserLocation(user);

  return (
    <Box sx={sxAssignToBox}>
      {alert && (
        <m.div
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <Alert
            icon={alert.type === 'error' ? <Error /> : undefined}
            action={
              alert.type === 'info' ? (
                <Button color="info" variant="contained" onClick={applyAutoDetection}>
                  Refresh
                </Button>
              ) : undefined
            }
            severity={alert.type as AlertColor}
          >
            {alert.text}
          </Alert>
        </m.div>
      )}
      <ListItem disablePadding component="div">
        <Tooltip title={loading ? 'Identifying recipient' : ''}>
          <ListItemButton sx={{ ...sxAssignButton(theme) }}>
            <Box display="flex" flexDirection="row" alignItems={location?.name ? 'top' : 'center'} flex="1">
              <ListItemAvatar>
                <Avatar sx={sxAvatarBigSize} alt={`${user?.displayName}`} src={user?.profileImageUrl}>
                  {user && !user?.profileImageUrl ? (
                    `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
                  ) : (
                    <PersonIcon sx={sxIconGrey500} />
                  )}
                </Avatar>
              </ListItemAvatar>
              {user ? (
                <ListItemText
                  primary={<Typography variant="subtitle1">{`${user.firstName} ${user.lastName}`}</Typography>}
                  secondary={
                    <>
                      <Typography color="text.secondary" variant="body1">
                        {user.userName}
                      </Typography>
                      {location?.name &&
                        (routes.isLoading || isLocationResponseLoading ? (
                          <Skeleton variant="text" width="40%" />
                        ) : (
                          <Box sx={{ display: 'flex', mt: 0.5 }}>
                            <Iconify
                              icon={'material-symbols:mode-of-travel'}
                              width={16}
                              height={16}
                              color={'grey.500'}
                              sx={{ m: '0 4px 0 0' }}
                            />

                            <Typography variant="caption">
                              {getRoutesList(routes.data)}
                              {location?.name}
                            </Typography>
                          </Box>
                        ))}
                    </>
                  }
                />
              ) : (
                <Typography variant="subtitle1">{'Unassigned user'}</Typography>
              )}
            </Box>
            {loading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={22} sx={sxSearchCircle} />
              </Box>
            )}
          </ListItemButton>
        </Tooltip>
      </ListItem>
    </Box>
  );
};

export default AssignTO;
